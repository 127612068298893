<template>
  <div>
    <div class="small text-primary" @click="$bvModal.show(modal_id)">
      {{ $t('get_help') }}
    </div>
    <b-modal :id="modal_id" centered scrollable size="sm" hide-footer hide-header title="BootstrapVue">
      <div class="p-4">
        <!-- <h5 class="mb-4">{{ title }}</h5> -->
        <h6>{{ content }}</h6>
        <div class="text-center">
          <b-button pill class="px-4 mt-4" variant="primary" @click="$bvModal.hide(modal_id)">{{
            $t('got_it')
          }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'GetHelp',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    modal_id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      required: true,
    },
  },
}
</script>

<style></style>
